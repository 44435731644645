import { Icon } from 'fitify-ui'
import Navigation from 'fitify-ui-landing/src/components/Navigation/Navigation'
import { usePromoLink } from 'fitify-ui-landing/src/utils/link'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'

// STATIC
import { footerCopy, footerNavigation, footerSocial } from '@/data/footer'
import { navigationData } from '@/data/navbar'

const Footer = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Footer/Footer')
)
const Newsletter = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Newsletter/Newsletter')
)

const Layout = ({
  seo,
  shouldHideNewsletter,
  children,
}: {
  seo: { title?: string; description?: string; openGraph?: OpenGraph }
  shouldHideNewsletter?: boolean
  children: React.ReactNode
}) => {
  const { t } = useTranslation()

  const promoLink = usePromoLink(process.env.NEXT_PUBLIC_ONBOARDING_URL)

  return (
    <>
      <NextSeo
        title={seo.title}
        description={seo.description}
        openGraph={seo.openGraph}
      />
      <Navigation
        items={navigationData}
        options={{ mobileEnabled: true }}
        logo={<Icon name={'fitify-logo-temporary'} />}
      />

      <div>
        {children}

        {!shouldHideNewsletter && (
          <Newsletter
            blockText="hc_landing_emailing_block_text"
            emailPlaceholderText="hc_landing_emailing_block_your_email"
            submitButtonText="hc_landing_emailing_block_button"
            successMessage="hc_landing_newsletter_success_messages"
            errorMessage="hc_landing_newsletter_error"
          />
        )}

        <Footer
          companyName={`${t('landing_contact_us_company_name')}`}
          copyLinks={footerCopy}
          socialLinks={footerSocial}
          navigationLinks={[
            {
              type: 'link',
              caption: 'hc_landing_get_your_trainer',
              link: promoLink,
              link_type: '_blank',
              isHiddenOnMobile: false,
            },
            ...footerNavigation,
          ]}
          logo={<Icon name={'fitify-hc-logo-pictogram'} />}
          storeLinks={[
            {
              name: 'App Store',
              icon: 'appstore-badge',
              link: 'https://apps.apple.com/us/app/fitify-1-on-1-personal-trainer/id1248388898',
            },
            {
              name: 'Google Play',
              icon: 'googleplay-badge',
              link: 'https://play.google.com/store/apps/details?id=com.fitifyworkouts.personaltrainer',
            },
          ]}
        />
      </div>
    </>
  )
}

export default Layout
